import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Grid, IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import {
  getDefaultAlertProps,
  getRootLevel,
  getViewType,
  replaceQuoat,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  LEVEL_READ,
  MAIN_COLOR_STRING,
  PC_VIEW,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetInfo } from '../../service/info/apiGetInfo';
import { apiUpdateInfo } from '../../service/info/apiUpdateInfo';

export const DeviceInfoDiv = () => {
  const { id } = useParams();
  const root = getRootLevel();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [checkHelper] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const getInfo = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetInfo(idDevice);
    if (apiResult.isSuccess) {
      setDeviceDto(apiResult.data.info);
      document.title = `${t('flowMeter')} № ${apiResult.data.info.deviceNum}`;
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateInfo = async () => {
    const apiResult: IApiResult = await apiUpdateInfo(deviceInfoDto);
    if (apiResult.isSuccess) {
      setIsEdit(false);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getInfo(id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('info')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto &&
              `${t('flowMeter')} № ${deviceInfoDto?.deviceNum} ${replaceQuoat(
                deviceInfoDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Stack
            direction={getViewType() !== PC_VIEW ? 'column' : 'row'}
            spacing={2}
          >
            <Card
              style={{
                padding: '20px',
                background: '#f5f5f5',
              }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack direction="row">
                    {root !== LEVEL_READ && (
                      <IconButton
                        title={t('tooltipEdit')}
                        size="medium"
                        onClick={() => setIsEdit(!isEdit)}
                      >
                        {!isEdit && (
                          <EditIcon
                            fontSize="medium"
                            sx={{ color: '#f44336' }}
                          />
                        )}
                        {isEdit && (
                          <EditOffIcon
                            fontSize="medium"
                            sx={{ color: '#f44336' }}
                          />
                        )}
                      </IconButton>
                    )}
                    <IconButton
                      disabled={!isEdit}
                      title={t('tooltipSave')}
                      size="medium"
                      onClick={updateInfo}
                    >
                      <SaveIcon
                        fontSize="medium"
                        sx={{ color: isEdit ? MAIN_COLOR_STRING : 'gray' }}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.holder, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            holder: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('holder')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.contract, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            contract: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('contract')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.inn}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            inn: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('inn')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.persAcc}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            persAcc: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('persAcc')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={replaceQuoat(deviceInfoDto?.location, 0)}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            location: replaceQuoat(e.target.value, 1),
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('address')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      spacing="1"
                    >
                      <MyTextFiled
                        value={deviceInfoDto?.dateSert}
                        onChange={(e) =>
                          setDeviceDto(
                            deviceInfoDto && {
                              ...deviceInfoDto,
                              dateSert: e.target.value,
                            }
                          )
                        }
                        error={checkHelper}
                        disabled={!isEdit}
                        type="text"
                        label={t('sertDate')}
                        size="small"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.gmt}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            gmt: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="number"
                      label={t('gmt')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.phone}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            phone: e.target.value,
                          }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="text"
                      label={t('phone')}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextFiled
                      value={deviceInfoDto?.email}
                      onChange={(e) =>
                        setDeviceDto(
                          deviceInfoDto && {
                            ...deviceInfoDto,
                            email: e.target.value,
                          }
                        )
                      }
                      disabled={!isEdit}
                      error={checkHelper}
                      type="text"
                      label={t('email')}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
