import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DevicesIcon from '@mui/icons-material/Devices';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import { MainDivGridItem } from './MainDivGridItem';
import { MainStatDto } from '../../dto/MainStatDto';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetStats } from '../../service/device/apiGetStats';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

export const MainDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [mainStatsDto, setMainStats] = useState<MainStatDto>();

  const getStats = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetStats();
    if (apiResult.isSuccess) {
      setMainStats(apiResult.data.stats);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={t('dashboard')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Grid container spacing={1}>
            <MainDivGridItem
              image={DevicesIcon}
              color="#ff5722"
              param={DeviceStageEnum.ALL}
              value={mainStatsDto?.cntSystem}
            />
          </Grid>
        </Stack>
      </Stack>
    </div>
  );
};
