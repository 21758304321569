import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#009688',
    },
  },
});

export const SignNavigationBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static">
        <Toolbar>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'revert',
                  fontWeight: 750,
                  fontSize: '28px',
                  color: '#FFFFFF',
                }}
                component="text"
              >
                TS-
              </Typography>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 100,
                  fontSize: '28px',
                  color: '#FFFFFF',
                }}
                component="text"
              >
                Manager
              </Typography>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  </Box>
);
