import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSelectedLng } from '../utils/utils';
import { LANGUAGE_RUS } from '../constant/constants';

i18n.use(initReactI18next).init({
  resources: {
    rus: {
      translation: {
        // Расшифровка ошибок
        errFlowMax: '1 - Превышен максимальный расход',
        errTempLimit: '2 - Температура вышла за пределы контроля',
        errMagnet: '3 - Обнаружено воздействие магнита',
        errCalibErr: '4 - Ошибка калибровки канала температуры',
        errBox: '5 - Открыт основной отсек',
        errAdc: '6 - Отсутствует измерительный модуль',
        errBox2: '7 - Открыт батарейный отсек',

        // Авторизация
        signIn: 'Авторизация',
        ip: 'IP локального сервера',
        login: 'Логин',
        password: 'Пароль',
        enter: 'Войти',

        // Подсказки
        tooltipExit: 'Выйти',
        tooltipGroupPrint: 'Групповой отчет',
        tooltipGroupTask: 'Групповая задача',
        tooltipEditDroup: 'Редактирование групп',
        tooltipAccount: 'Личные кабинеты',
        tooltipLink: 'Привязка группы счетчиков к пользователю',
        tooltipUpdate: 'Обновить',
        tooltipService: 'Сервис',
        tooltipResetArchive: 'Сбросить запрашиваемый период архива',
        tooltipDelete: 'Удалить',
        tooltipMoveToArch: 'Перевести в архив',
        tooltipAdd: 'Добавить',
        tooltipSave: 'Сохранить',
        tooltipBack: 'Назад',
        tooltipLook: 'Просмотр',
        tooltipEdit: 'Редактировать',
        tooltipArshin: 'Подтянуть из АРШИН',
        tooltipDeleteTask: 'Отменить задачу',
        tooltipDeleteUpdate: 'Отменить обновление',
        tooltipInfo: 'Информация',
        tooltipPrint: 'Отчеты',
        tooltipChart: 'Графики',
        tooltipSetup: 'Настройки',
        tooltipPdf: 'Сохранить в PDF',
        tooltipExcel: 'Сохранить в Excel',

        // Сервис
        headerServiceDeviceNum: 'Зав. номер',
        headerServiceImei: 'IMEI',
        headerServiceHolder: 'Потребитель',
        headerServiceVoltBatMain: 'Vпит осн., В',
        headerServiceVoltBatReserv: 'Vпит рез., В',
        headerServiceVoltProc: 'Vпроц, В',
        headerServiceTempProc: 'Tпроц, °С',
        headerServiveFwVer: 'Прошивка',
        headerServiceGprsSendCnt: 'GPRS кол. отпр.',

        // Главное окно
        dashboard: 'Главная',
        search: 'Поиск...',
        flowSystem: 'Приборов в системе',
        activeFlow: 'Активных заданий',
        flowWarning: 'Счетчиков с тревогой',
        flowConnect: 'Потеря связи',
        flowUpdate: 'Задано обновление',
        flowArchive: 'В архиве',
        flowSert: 'Истекает поверка',
        flowBattery: 'Замена батарейки',
        flowLog: 'Журнал сервера',
        flowSearch: 'Результаты поиска',
        service: 'Сервис',
        shows: 'Показания, м³',

        // Окно подтверждения
        confirmDeleteFlowmeter:
          'Вы действительно хотите удалить данный счетчик?',
        confirmDeleteUser:
          'Вы действительно хотите удалить данного пользователя?',
        confirmDeleteGroup: 'Вы действительно хотите удалить данную группу?',
        confirmWindowTitle: 'Требуется подтверждение действия',
        cancel: 'Отмена',
        delete: 'Удалить',

        // Список приборов
        headerListPres: 'Абс. давл., кПа',
        headerListTemp: 'Темп., °C',
        headerListComp: 'Коэф. сжим.',
        headerListSumVolSt: 'Объем ст., м³',

        // Окно архивов и журналов
        report: 'Отчеты',
        headerArchDatetime: 'Дата и время',
        headerArchAbsPres: 'Абс. давл., кПа',
        headerArchAtmPres: 'Атм. давл., кПа',
        headerArchStatus: 'Статус регистров',
        headerArchCode: 'Код записи',
        headerArchTemp: 'Темп., °C',
        headerArchComp: 'Коэф. сжим.',
        headerArchVolSt: 'Объем ст., м³',
        headerArchVolErrSt: 'Возм. объем ст., м³',
        headerArchVol: 'Объем раб., м³',
        headerArchVolErr: 'Возм. объем раб., м³',
        headerEventsDatetime: 'Дата и время',
        headerEventsCode: 'Событие',
        headerEvents: 'Сообщение',
        headerChangesDatetime: 'Дата и время',
        headerChangesCode: 'Параметр',
        headerChangesOldValue: 'Старое значение',
        headerChangesNewValue: 'Новое значение',
        headerChangesStatus: 'Статус замков',
        headerSetupParam: 'Настройка',
        headerSetupValue: 'Значение',

        // Общее
        flowMeter: 'Прибор',
        group: 'Группа',

        // Групповой отчет
        groupReport: 'Групповой отчет',
        reportType: 'Тип отчета',
        documentFormat: 'Формат документа',
        reportPeriod: 'Отчетный период',
        day: 'День',
        month: 'Месяц',
        year: 'Год',
        date: 'Дата',
        beginPeriod: 'Начало периода',
        endPeriod: 'Конец периода',
        form: 'Сформировать',
        hourArchive: 'Часовой',
        dayArchive: 'Суточный',
        monthArchive: 'Месячный',
        eventsJournal: 'События',
        changesJournal: 'Изменения',

        // Групповая задача
        groupTask: 'Групповая задача',
        taskListEmpty: 'Список задач пуст',
        taskList: 'Список задач для отправки',
        taskDone: 'Задать',
        setup: 'Настройка',
        value: 'Значение',

        // Редактирвоание групп
        groupEdit: 'Редактирование групп',
        groupList: 'Список групп',
        groupListEmpty: 'Список групп пуст',
        save: 'Сохранить',
        name: 'Наименование',
        notChoose: 'Не выбрана',

        // Информация и настройки
        titleUpp: 'Условно-постоянные и подстановочные значения',
        titleContr: 'Договорные значения',
        titleServer: 'Настройки сервера',
        titleOther: 'Другие настройки',
        titleControl: 'Действия при срабатывании контроля',
        titleSystem: 'Системные настройки',

        password1: 'Пароль потребителя',
        password2: 'Пароль поставщика',
        resetMagnet: 'Сброс ошибки воздейсвтия магнита',
        resetBox: 'Сброс ошибки открытия основного отсека',
        resetFlowMax: 'Сброс ошибки максимального расхода',
        resetTempLimit: 'Сброс ошибки предела контроля температуры',
        resetAdcd: 'Сброс ошибки измерительного модуля',
        resetCalibErr: 'Сброс ошибки калибровки температуры',
        resetBox2: 'Сброс ошибки открытия батарейного отсека',
        resetBattery: 'Сброс остаточного заряда',
        syncdatetime: 'Синхронизировать время',
        gmt: 'Часовой пояс',
        edit: 'Редактировать',
        setups: 'Настройки',
        holder: 'Потребитель',
        address: 'Адрес установки',
        deviceType: 'Тип устройства',
        deviceNum: 'Заводской номер',
        sertDate: 'Дата поверки',
        constPres: 'Условно-постоянное значение абсолютного давления, кПа',
        constKomp: 'Условно-постоянное значение коэффициента сжимаемости',
        tempMeasPeriod: 'Период измерения температуры, с',
        contrVal:
          'Договорное значение расхода в стандартных условиях (С.У.), м³/ч',
        contrHour: 'Контрактный час',
        contrDay: 'Контрактный день',
        dispOn: 'Дисплей всегда включен',
        dispRotate: 'Автоматическая прокрутка меню дисплея',
        minTempLimit: 'Нижняя граница контроля температуры, °C',
        constMinTemp:
          'Подстановочное значение темп. при выходе за нижнюю границу контроля, °C',
        actionMinTemp:
          'Действие при выходе температуры за нижний предел контроля',
        maxTempLimit: 'Верхняя граница контроля температуры, °C',
        constMaxTemp:
          'Подстановочное значение темп. при выходе за верхнюю границу контроля, °C',
        actionMaxTemp:
          'Действие при выходе температуры за верхний предел контроля',
        actionMagnet: 'Действие при воздействии магнитом на счётный механизм',
        actionMinFlow: 'Действие при выходе за минимальный расход счётчика',
        actionMaxFlow: 'Действие при выходе за максимальный расход счётчика',
        server:
          'Адрес сервера (в формате [ip-адрес]:[порт], например 192.168.1.1:8000)',
        minSign: 'Минимальный уровень сигнала',
        minGprsTemp: 'Минимальная температура отправки GPRS',
        repeatCount: 'Количество повторов отправки',
        contract: 'Договор',
        inn: 'ИНН',
        persAcc: 'Лицевой счет',
        kategory: 'Категория',
        phone: 'Телефон',
        email: 'Почта',
        info: 'Информация',
        infoDevice: 'Информация по счетчику',
        imei: 'IMEI',
        procId: 'ID процессора',
        fwVers: 'Версия ПО',
        voltBat: 'Напряжение батарейки, В',
        voltProc: 'Напряжения процессора, В',
        tempProc: 'Температура процессора, °С',
        timeLeft: 'Остаточный заряд, %',
        flowMeterValues: 'Показания счетчика',
        temp: 'Температура, °С',
        pres: 'Абсолютное давление, кПа',
        komp: 'Коэффициент сжимаемости',
        sumVolSt: 'Накопленный объем (ст. усл.), м³',
        events: 'События',
        datetime: 'Дата и время',
        plombNum: 'Электронная пломба',
        plombBroken: 'Пломба сорвана',
        stats: 'Статистика',
        timeTotal: 'Общее время работы, ч',
        timeNewBat: 'Время работы от новой батарейки, ч',
        connect: 'Подключение',
        connectNext: 'След. подключение',
        dateCreate: 'Первое',
        dateLastCon: 'Последнее',
        dateNextCon: 'Следующее',
        actionJournalOnly: 'Только запись в журнал',
        actionContrVal: 'Использовать договорное значение расхода в С.У.',
        actionConstTemp: 'Использовать подстановочное значение температуры',
        dispOnStatusOff: 'Отключен',
        dispOnStatusOn: 'Включен',
        dispRotateStatusOff: 'Отключена',
        dispRotateStatusOn: 'Включена',
        people: 'Физическое лицо',
        noError: 'Нет ошибок',
        factory: 'Юридическое лицо',

        // График
        chart: 'Графики',
        lineChart: 'Линейный график',
        diagramma: 'Диаграмма',
        areaChart: 'Закрашенный график',
        volumeChart: 'Объем ст., м³',
        tempChart: 'Температура, °C',
        dateTimeChart: 'Дата и время',

        // Личные кабинеты
        accountEdit: 'Личные кабинеты',
        loginName: 'Имя пользователя',
        create: 'Создать',
        read: 'Чтение',
        readWrite: 'Чтение и запись',
        admin: 'Администратор',
        root: 'Уровень доступа',
        action: 'Действие',
        userLink: 'Привязка группы счетчиков к пользователю',
        flowmeterGroupId: 'Идентифкатор группы счетчиков',

        // Статусы API
        errotNotFoundAccount:
          'Такого личного кабинет нет. Проверьте логин и пароль',
        errotServer:
          'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже',
        successAddUser: 'Пользователь добавлен',
        errorLoginConflict: 'Такой пользователь существует',
        successDeletAccount: 'Пользователь удален',
        errorGroupConflict: 'Такая группа существует',
        successDeletGroup: 'Группа удалена',
        successAddGroup: 'Группа добавлена',
        needUpdate: 'ВНИМАНИЕ! Необходимо обновить локальный сервер до версии',

        // Логи
        logDeleteUser: 'Удален пользователь',
        logLogout: 'Выход из кабинета',
        logSignIn: 'Авторизация',
        logSignUp: 'Добавлен новый пользователь',
        logAddGroup: 'Добавлена новая группа',
        logDeleteGroup: 'Группа удалена',
        logAddGroupTask: 'Добавлена групповая задача',
        logUpdtateUserFlowmeterGroupId:
          'Обновлена информация привязки пользователя к счетчикам',
        logDeleteDevice: 'Удален счетчик',
        logMoveToArch: 'Счетчик переведен в архив',
        logUpdateInfo: 'Обновление информации о счетчике',
        logUpdateSetup: 'Обновление задачи счетчика',
        logDeleteSetup: 'Удаление задачи счетчика',
        logUpdateFw: 'Задано обновление счетчика',
        logDeleteUpdateFw: 'Отменено обновление счетчика',

        // Надпись под полем ввода
        enterValue: 'Введите значение',
      },
    },
  },
  lng: getSelectedLng(),
  fallbackLng: LANGUAGE_RUS,
});

export default i18n;
