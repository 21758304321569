import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, Stack, ThemeProvider, createTheme } from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  otherToDataGrid,
  replaceQuoat,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  LANGUAGE_RUS,
  PAGE_SIZE_SETUP,
  START_PAGE_SIZE_SETUP,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DeviceSetupDto } from '../../dto/DeviceSetupDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetSetup } from '../../service/setup/apiGetSetup';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';

export const DeviceSetupDiv = () => {
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_SETUP);
  const { id } = useParams();
  const [backDrop, setBackDrop] = useState(false);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();

  const [deviceSetupDto, setDeviceSetupDto] = useState<DeviceSetupDto[]>([]);

  const getSetup = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetSetup(idDevice);
    if (apiResult.isSuccess) {
      setDeviceDto(apiResult.data.info);
      setDeviceSetupDto(apiResult.data.params);
      document.title = `${t('flowMeter')} № ${apiResult.data.info.deviceNum}`;
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const columnsParams: GridColDef[] = [
    {
      field: 'param',
      headerName: t('headerSetupParam'),
      renderHeader: () => <strong>{t('headerSetupParam')}</strong>,
      flex: 1,
      minWidth: 500,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.param,
    },
    {
      field: 'value',
      headerName: t('headerSetupValue'),
      renderHeader: () => <strong>{t('headerSetupValue')}</strong>,
      flex: 1,
      minWidth: 500,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.value,
    },
  ];

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getSetup(id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('setups')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto &&
              `${t('flowMeter')} № ${deviceInfoDto?.deviceNum} ${replaceQuoat(
                deviceInfoDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Card style={{ padding: '20px', background: '#f5f5f5' }}>
            <Box
              sx={{
                width: '100%',
                '& .stage-green': {
                  backgroundColor: 'green',
                },
                '& .stage-orange': {
                  backgroundColor: 'orange',
                },
                '& .stage-red': {
                  backgroundColor: '#ef5350',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    background: '#f5f5f5',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '5px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columnsParams}
                  rows={deviceSetupDto}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...otherToDataGrid}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={PAGE_SIZE_SETUP}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
