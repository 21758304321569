import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  formatDateArch,
  getDefaultAlertProps,
  getDefaultDateToReport,
  getDisabledReport,
  getReportTypeArray,
  getSelectedLng,
  otherToDataGrid,
  replaceQuoat,
  roundValue,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  EXCEL_ARCHIVE,
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_ARCHIVES,
  PDF_ARCHIVE,
  ROUND_PRES,
  ROUND_TEMP,
  ROUND_VOLUME,
  ROUND_VOLUME_ALL,
  START_PAGE_SIZE_ARCHIVES,
  VIEW_ARCHIVE,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { ReportsTypeEnum } from '../../enum/ReportsTypeEnum';
import { DeviceArchiveDto } from '../../dto/DeviceArchiveDto';
import { DeviceEventsDto } from '../../dto/DeviceEventsDto';
import { DeviceChangesDto } from '../../dto/DeviceChangesDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetHours } from '../../service/archive/apiGetHours';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyDateTextField } from '../UI/MyDateTextField';
import { apiGetDays } from '../../service/archive/apiGetDays';
import { apiGetMonths } from '../../service/archive/apiGetMonths';
import { apiGetEvetns } from '../../service/archive/apiGetEvents';
import { apiGetChanges } from '../../service/archive/apiGetChanges';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';

export const DeviceArchiveDiv = () => {
  const { id } = useParams();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [dateTimeArch, setDateTimeArch] = useState(getDefaultDateToReport(0));
  const [deviceNum, setDeviceNum] = useState('');
  const [hourArchiveDtos, setHourArchiveDtos] = useState<DeviceArchiveDto[]>(
    []
  );
  const [dayArchiveDtos, setDayArchiveDtos] = useState<DeviceArchiveDto[]>([]);
  const [monthArchiveDtos, setMonthArchiveDtos] = useState<DeviceArchiveDto[]>(
    []
  );
  const [eventsDtos, setEventsDtos] = useState<DeviceEventsDto[]>([]);
  const [changesDtos, setChangesDtos] = useState<DeviceChangesDto[]>([]);
  const [valueTab, setValueTab] = useState(0);
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_ARCHIVES);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();
  const getReportData = (reportType: number) => {
    if (reportType === ReportsTypeEnum.HOUR) {
      return hourArchiveDtos;
    }
    if (reportType === ReportsTypeEnum.DAY) {
      return dayArchiveDtos;
    }
    if (reportType === ReportsTypeEnum.MONTH) {
      return monthArchiveDtos;
    }
    if (reportType === ReportsTypeEnum.EVENTS) {
      return eventsDtos;
    }
    if (reportType === ReportsTypeEnum.CHANGES) {
      return changesDtos;
    }
    return [];
  };

  const getArch = async (
    idDevice: string,
    type: number,
    print: string,
    datetime?: string,
    name?: string
  ) => {
    setBackDrop(true);
    if (type === ReportsTypeEnum.HOUR) {
      const apiResult: IApiResult = await apiGetHours(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        if (print === VIEW_ARCHIVE) {
          setHourArchiveDtos(apiResult.data.hour);
          document.title = `${t('flowMeter')} № ${
            apiResult.data.info.deviceNum
          }`;
          setDeviceDto(apiResult.data.info);
          setDeviceNum(apiResult.data.info.deviceNum);
        } else {
          FileDownload(apiResult.data, `Часовой_${name}` ?? '');
        }
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.DAY) {
      const apiResult: IApiResult = await apiGetDays(idDevice, print, datetime);
      if (apiResult.isSuccess) {
        if (print === VIEW_ARCHIVE) {
          setDayArchiveDtos(apiResult.data.day);
          document.title = `${t('flowMeter')} № ${
            apiResult.data.info.deviceNum
          }`;
          setDeviceNum(apiResult.data.info.deviceNum);
        } else {
          FileDownload(apiResult.data, `Суточный_${name}` ?? '');
        }
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.MONTH) {
      const apiResult: IApiResult = await apiGetMonths(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        if (print === VIEW_ARCHIVE) {
          setMonthArchiveDtos(apiResult.data.month);
          document.title = `${t('flowMeter')} № ${
            apiResult.data.info.deviceNum
          }`;
          setDeviceNum(apiResult.data.info.deviceNum);
        } else {
          FileDownload(apiResult.data, `Месячный_${name}` ?? '');
        }
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.EVENTS) {
      const apiResult: IApiResult = await apiGetEvetns(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        if (print === VIEW_ARCHIVE) {
          setEventsDtos(apiResult.data.events);
          document.title = `${t('flowMeter')} № ${
            apiResult.data.info.deviceNum
          }`;
          setDeviceNum(apiResult.data.info.deviceNum);
        } else {
          FileDownload(apiResult.data, `События_${name}` ?? '');
        }
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    if (type === ReportsTypeEnum.CHANGES) {
      const apiResult: IApiResult = await apiGetChanges(
        idDevice,
        print,
        datetime
      );
      if (apiResult.isSuccess) {
        if (print === VIEW_ARCHIVE) {
          setChangesDtos(apiResult.data.changes);
          document.title = `${t('flowMeter')} № ${
            apiResult.data.info.deviceNum
          }`;
          setDeviceNum(apiResult.data.info.deviceNum);
        } else {
          FileDownload(apiResult.data, `Изменения_${name}` ?? '');
        }
      } else {
        setAlertProps({
          message: t('errotServer'),
          severity: AlertSeverityEnum.error,
        });
      }
    }
    setBackDrop(false);
  };

  const handleChangeTab = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueTab(newValue);
    await getArch(
      id ?? '',
      newValue,
      VIEW_ARCHIVE,
      formatDateArch(dateTimeArch)
    );
  };

  const handleViewArchive = async () => {
    await getArch(
      id ?? '',
      valueTab,
      VIEW_ARCHIVE,
      formatDateArch(dateTimeArch)
    );
  };

  const handlePdfArchive = async () => {
    await getArch(
      id ?? '',
      valueTab,
      PDF_ARCHIVE,
      formatDateArch(dateTimeArch),
      `${deviceNum}_${formatDateArch(dateTimeArch)}.pdf`
    );
  };

  const handleExcelArchive = async () => {
    await getArch(
      id ?? '',
      valueTab,
      EXCEL_ARCHIVE,
      formatDateArch(dateTimeArch),
      `${deviceNum}_${formatDateArch(dateTimeArch)}.xlsx`
    );
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columnsArchive: GridColDef[] = [
    {
      field: 'datetime',
      headerName: t('headerArchDatetime'),
      renderHeader: () => <strong>{t('headerArchDatetime')}</strong>,
      minWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.datetime,
    },
    {
      field: 'volumeSt',
      headerName: t('headerArchVolSt'),
      renderHeader: () => <strong>{t('headerArchVolSt')}</strong>,
      flex: 1,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.volumeSt, ROUND_VOLUME),
    },
    {
      field: 'volumeErrSt',
      headerName: t('headerArchVolErrSt'),
      renderHeader: () => <strong>{t('headerArchVolErrSt')}</strong>,
      flex: 1,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.volumeErrSt, ROUND_VOLUME_ALL),
    },
    {
      field: 'volume',
      headerName: t('headerArchVol'),
      renderHeader: () => <strong>{t('headerArchVol')}</strong>,
      flex: 1,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.volume, ROUND_VOLUME),
    },
    {
      field: 'volumeErr',
      headerName: t('headerArchVolErr'),
      renderHeader: () => <strong>{t('headerArchVolErr')}</strong>,
      flex: 1,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.volumeErr, ROUND_VOLUME_ALL),
    },
    {
      field: 'temp',
      headerName: t('headerArchTemp'),
      renderHeader: () => <strong>{t('headerArchTemp')}</strong>,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.temp, ROUND_TEMP),
    },
    {
      field: 'absPres',
      headerName: t('headerArchAbsPres'),
      renderHeader: () => <strong>{t('headerArchAbsPres')}</strong>,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.absPres, ROUND_PRES),
    },
    {
      field: 'atmPres',
      headerName: t('headerArchAtmPres'),
      renderHeader: () => <strong>{t('headerArchAtmPres')}</strong>,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => roundValue(row.row.atmPres, ROUND_PRES),
    },
    {
      field: 'status',
      headerName: t('headerArchStatus'),
      renderHeader: () => <strong>{t('headerArchStatus')}</strong>,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.status,
    },
    {
      field: 'code',
      headerName: t('headerArchCode'),
      renderHeader: () => <strong>{t('headerArchCode')}</strong>,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.code,
    },
  ];

  const columnsEvents: GridColDef[] = [
    {
      field: 'datetime',
      headerName: t('headerEventsDatetime'),
      renderHeader: () => <strong>{t('headerEventsDatetime')}</strong>,
      minWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.datetime,
    },
    {
      field: 'code',
      headerName: t('headerEventsCode'),
      renderHeader: () => <strong>{t('headerEventsCode')}</strong>,
      flex: 1,
      minWidth: 500,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.code,
    },
    {
      field: 'event',
      headerName: t('headerEvents'),
      renderHeader: () => <strong>{t('headerEvents')}</strong>,
      flex: 1,
      minWidth: 500,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.event,
    },
  ];

  const columnsChanges: GridColDef[] = [
    {
      field: 'datetime',
      headerName: t('headerChangesDatetime'),
      renderHeader: () => <strong>{t('headerChangesDatetime')}</strong>,
      minWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.datetime,
    },
    {
      field: 'code',
      headerName: t('headerChangesCode'),
      renderHeader: () => <strong>{t('headerChangesCode')}</strong>,
      flex: 1,
      minWidth: 500,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.code,
    },
    {
      field: 'oldVal',
      headerName: t('headerChangesOldValue'),
      renderHeader: () => <strong>{t('headerChangesOldValue')}</strong>,
      minWidth: 250,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.oldVal,
    },
    {
      field: 'newVal',
      headerName: t('headerChangesNewValue'),
      renderHeader: () => <strong>{t('headerChangesNewValue')}</strong>,
      minWidth: 250,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.newVal,
    },
    {
      field: 'status',
      headerName: t('headerChangesStatus'),
      renderHeader: () => <strong>{t('headerChangesStatus')}</strong>,
      minWidth: 250,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.status,
    },
  ];

  const getReportColumns = (reportType: number) => {
    if (reportType === ReportsTypeEnum.HOUR) {
      return columnsArchive;
    }
    if (reportType === ReportsTypeEnum.DAY) {
      return columnsArchive;
    }
    if (reportType === ReportsTypeEnum.MONTH) {
      return columnsArchive;
    }
    if (reportType === ReportsTypeEnum.EVENTS) {
      return columnsEvents;
    }
    if (reportType === ReportsTypeEnum.CHANGES) {
      return columnsChanges;
    }
    return columnsArchive;
  };

  const [period, setPeriod] = useState('date');

  const handleChangePeriod = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPeriod((event.target as HTMLInputElement).value);
    setDateTimeArch(
      getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
    );
    await getArch(
      id ?? '',
      valueTab,
      VIEW_ARCHIVE,
      formatDateArch(
        getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
      )
    );
  };

  useEffect(() => {
    getArch(id ?? '', valueTab, VIEW_ARCHIVE, formatDateArch(dateTimeArch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('report')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto &&
              `${t('flowMeter')} № ${deviceInfoDto?.deviceNum} ${replaceQuoat(
                deviceInfoDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Card style={{ padding: '20px', background: '#f5f5f5' }}>
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  spacing={2}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t('reportPeriod')}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="date"
                      onChange={handleChangePeriod}
                    >
                      <FormControlLabel
                        value="date"
                        control={<Radio />}
                        label={t('day')}
                      />
                      <FormControlLabel
                        value="month"
                        control={<Radio />}
                        label={t('month')}
                      />
                      <FormControlLabel
                        value="number"
                        control={<Radio />}
                        label={t('year')}
                      />
                    </RadioGroup>
                  </FormControl>
                  <MyDateTextField
                    type={period}
                    value={dateTimeArch}
                    onChange={(e) => setDateTimeArch(e.target.value)}
                    width="200px"
                    label={t('date')}
                    size="small"
                  />
                  <IconButton
                    size="medium"
                    onClick={handleViewArchive}
                    title={t('tooltipLook')}
                    sx={{ color: MAIN_COLOR_STRING }}
                  >
                    <FindInPageIcon fontSize="large" />
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="medium"
                    onClick={handlePdfArchive}
                    title={t('tooltipPdf')}
                    sx={{ color: '#f44336' }}
                  >
                    <PictureAsPdfIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    size="medium"
                    onClick={handleExcelArchive}
                    title={t('tooltipExcel')}
                    sx={{ color: '#4caf50' }}
                  >
                    <ArticleIcon fontSize="large" />
                  </IconButton>
                </Stack>
              </Stack>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Tabs
                  value={valueTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  {getReportTypeArray().map((item) => (
                    <Tab
                      key={item}
                      disabled={getDisabledReport(item, deviceInfoDto)}
                      label={item}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  '& .stage-green': {
                    backgroundColor: 'green',
                  },
                  '& .stage-orange': {
                    backgroundColor: 'orange',
                  },
                  '& .stage-red': {
                    backgroundColor: '#ef5350',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      background: '#f5f5f5',
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                        fontSize: '100px',
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '5px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={getReportColumns(valueTab)}
                    rows={getReportData(valueTab)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...otherToDataGrid}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={PAGE_SIZE_ARCHIVES}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
